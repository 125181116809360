import React from 'react'
import { useTranslate } from 'react-polyglot'
import { PageProps } from 'gatsby'
import { PageContext } from 'src/types'
import { Helmet } from 'react-helmet'

import theme from 'src/lib/theme'

import PageContainer from 'src/components/PageContainer/PageContainer'
import Layout from 'src/components/Layout/Layout'
import DownloadInstructions from 'src/sections/DownloadInstruction/DownloadInstruction'
import {
  trackStreamsWebPromotion,
  trackStreamsGiftVoucherRedemption,
} from 'src/lib/tracking'
import { asError, asResult, isPending } from 'src/lib/hooks'

import { usePromise } from 'src/lib/hooks'
import {
  getToken,
  redeemGiftVoucher,
} from 'src/components/StreamsSignupOrLoginPage/signup-api'
import { Box } from '@chatterbug/aaron'
import { useLocale } from 'src/lib/I18nContext'

const InstructionsPage: React.FC<PageProps<unknown, PageContext>> = ({
  pageContext,
}) => {
  const t = useTranslate()
  const isSSR = typeof window === 'undefined'
  const locale = useLocale()

  let giftVoucherRedemptionResult = usePromise(async () => {
    if (isSSR) return
    const url = new URL(window.location.href)
    const giftVoucherCode = url.searchParams.get('giftVoucherCode')

    if (!giftVoucherCode) {
      // If no giftVoucher is present, we probably came from redeeming a promotion
      // We track that a purchase was made via a promotion
      trackStreamsWebPromotion('purchased', 'download-instructions')
      return
    }

    const token = getToken()
    if (token) {
      const { data } = await redeemGiftVoucher(token, giftVoucherCode)

      const { redeemed, errors } = data.redeemStreamsGiftVoucher

      if (errors[0]?.code === 'already_has_streams_access') {
        window.location.href = `/${locale}/streams-subscription/exisiting-user`
      } else if (errors?.length > 0) {
        throw new Error(t('gift_voucher_redeem.could_not_redeem'))
      }

      if (redeemed === true) {
        trackStreamsGiftVoucherRedemption('redeemed', 'download-instructions')
        return redeemed
      } else {
        throw new Error(t('gift_voucher_redeem.could_not_redeem'))
      }
    } else {
      // User is not authorised
      throw new Error(t('gift_voucher_redeem.could_not_redeem'))
    }
  }, [isSSR])

  const result = asResult(giftVoucherRedemptionResult)
  const err = asError(giftVoucherRedemptionResult)
  const loading = isPending(giftVoucherRedemptionResult)

  return (
    <Layout
      pageContext={pageContext}
      background={theme.namedColors.streamsBackground}
      hideSiteHeader
      hideSiteFooter
    >
      <Helmet
        title={t('Download Instructions')}
        meta={[
          {
            name: 'robots',
            content: 'noindex, nofollow',
          },
        ]}
      />
      <PageContainer>
        {loading && (
          <Box
            as="p"
            marginTop="2x"
            paddingLeft="1x"
            color="blue42"
            textAlign="center"
            variant="text.buttonTitle"
          >
            {t('gift_voucher_redeem.redeeming')}
          </Box>
        )}
        {err && (
          <Box
            as="p"
            marginTop="2x"
            paddingLeft="1x"
            color="red44"
            textAlign="center"
            variant="text.buttonTitle"
          >
            {err.message}
          </Box>
        )}
        {result === true && (
          <Box
            as="p"
            marginTop="2x"
            paddingLeft="1x"
            color="blue42"
            textAlign="center"
            variant="text.buttonTitle"
          >
            {t('gift_voucher_redeem.redeemed')}
          </Box>
        )}
        <DownloadInstructions />
      </PageContainer>
    </Layout>
  )
}

export default InstructionsPage
