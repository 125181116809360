import React from 'react'
import { useTranslate } from 'react-polyglot'
import { Box, Flex, Image } from '@chatterbug/aaron'
import { getStreamsAppUrlsWithoutTracking } from 'src/lib/app'
import LineSeparator from 'src/components/LineSeparator/LineSeparator'
import MobileAppLinks from 'src/components/MobileAppLinks/MobileAppLinks'
import StreamsLogo from 'src/components/StreamsLogo/StreamsLogo'

import FirstStep from './assets/one.svg'
import SecondStep from './assets/two.svg'
import { asError, isPending, retry, usePromise } from 'src/lib/hooks'
import {
  createStripeSubscription,
  getToken,
} from 'src/components/StreamsSignupOrLoginPage/signup-api'

const stepIcons = {
  one: FirstStep,
  two: SecondStep,
}

const InstructionStep: React.FC<{
  stepNumber: 'one' | 'two'
  text: string
}> = ({ stepNumber, text }) => {
  return (
    <Flex
      flexDirection={{ _: 'column', laptop: 'row' }}
      alignItems={{ _: 'center', laptop: 'flex-start' }}
      mb={{
        _: '5x',
        laptop: stepNumber === 'one' ? '5x' : 0,
      }}
      data-testid="instruction-step"
    >
      <Image
        src={stepIcons[stepNumber]}
        width={32}
        height={32}
        flexShrink={0}
        mb={{ _: '2x', laptop: 0 }}
      />
      <Box
        color="black0"
        textAlign={{ _: 'center', laptop: 'left' }}
        ml={{ _: 0, laptop: 14 }}
        lineHeight={1.7}
        dangerouslySetInnerHTML={{
          __html: text,
        }}
      />
    </Flex>
  )
}

const DownloadInstructions: React.FC = () => {
  const t = useTranslate()
  const mobileUrls = getStreamsAppUrlsWithoutTracking()
  const isSSR = typeof window === 'undefined'

  let subAssocResult = usePromise(async () => {
    if (isSSR) return

    // NB: This is a Secret Handshake with StreamsSignupOrLoginPage so that we
    // can get back the authenticated session once Stripe sends us back
    const token = getToken()
    const url = new URL(window.location.href)
    const sessionId = url.searchParams.get('session_id')

    if (!sessionId || !token) {
      console.warn(
        `Unable to associate user ${token ?? '(none)'} with Stripe Session ${
          sessionId ?? '(none)'
        }`
      )
      return
    }

    await retry(() => createStripeSubscription(token, sessionId), 5)

    return true
  }, [isSSR])

  let errorContent: JSX.Element | undefined
  if (asError(subAssocResult)) {
    console.error(asError(subAssocResult))
    errorContent = (
      <Box as="p" paddingBottom="4x" color="red68" sx={{ fontSize: 's' }}>
        {t(
          'Something has gone wrong while setting up your subscription, please contact support'
        )}
      </Box>
    )
  }

  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      py={80}
      data-testid="download-instruction"
    >
      <Flex
        flexDirection="column"
        maxWidth={{ _: '100%', tablet: 331, laptop: '100%' }}
        textAlign="center"
      >
        <StreamsLogo height={57} iconOnly />
        {/* title */}
        <Box
          as="h3"
          variant="text.h3"
          pt="3x"
          pb={{ _: '4x', laptop: 72 }}
          color="black0"
        >
          {t('Just two more important steps!')}
        </Box>

        {/* This sucks being here but we want people to see it */}
        {errorContent}

        {/* instructional text */}
        <Flex flexDirection={{ _: 'column', laptop: 'row' }}>
          {/* left side */}
          <Flex flexDirection="column" flex={1}>
            <InstructionStep
              stepNumber="one"
              text={t(
                'Download the <strong>Streams app</strong> from the <strong>Apple App Store</strong> or <strong>Google Play</strong>'
              )}
            />
            <InstructionStep
              stepNumber="two"
              text={t(
                '<strong>Log in</strong> using the details you’ve just provided. The Login button can be found at the bottom of the first screen when you open the app'
              )}
            />
          </Flex>
          {/* separator */}
          <Box display={{ laptop: 'none' }}>
            <LineSeparator type="horizontal" />
          </Box>
          <Box display={{ _: 'none', laptop: 'block' }} mx={60}>
            <LineSeparator type="vertical" />
          </Box>

          {/* right side */}
          <Flex
            flex={1}
            flexDirection="column"
            alignItems="flex-start"
            mt={{ _: '5x', laptop: 0 }}
          >
            <>
              <Box
                as="h4"
                variant="text.h4"
                color="black0"
                mb="3x"
                width={{ _: '100%', laptop: 'auto' }}
              >
                {t('Download, Install, Log in')}
              </Box>
              {isPending(subAssocResult) ? (
                <Box width="100%" height="90" />
              ) : (
                <>
                  <Box
                    alignItems="center"
                    width="100%"
                    display={{ laptop: 'none' }}
                  >
                    <MobileAppLinks
                      iosAppUrls={mobileUrls.ios}
                      androidAppUrls={mobileUrls.android}
                      layout={'column'}
                    />
                  </Box>
                  <Box
                    alignItems="center"
                    width="100%"
                    display={{ _: 'none', laptop: 'block' }}
                  >
                    <MobileAppLinks
                      iosAppUrls={mobileUrls.ios}
                      androidAppUrls={mobileUrls.android}
                      layout={'row'}
                    />
                  </Box>
                </>
              )}
              <Box
                as="p"
                fontSize="xxs"
                color="black0"
                lineHeight={1.7}
                mt="3x"
                textAlign={{ _: 'center', laptop: 'left' }}
              >
                {t(
                  'This offer includes a seven day free trial. Cancel anytime. The trial is only available to new stream users. If you have downloaded and used streams before the trial will not be available to you.'
                )}
              </Box>
            </>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default DownloadInstructions
